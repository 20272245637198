import React from 'react';
import Search from '~/2-components/Search/Search';
import SgLayout from "~layouts/sg-layout";

const SearchPage = () => (
    <SgLayout>
        <div className="page-content">
            <div className="container">
                <Search />
            </div>
        </div>
    </SgLayout>
);

export default SearchPage;
